<template>
  <div id="products-container" class="">
    <div v-for="(product, index) in this.products" :key="index" class="w-full px-6">
      <div v-if="index % 2 == 0"
        class="flex md:flex-row flex-col justify-between items-center w-full bg-cardBg px-6 pt-6 my-10 rounded-3xl shadow-md overflow-hidden">
        <div data-aos="fade-right" class="md:text-left text-center md:w-1/2 w-full">
          <h2 class="lg:text-5xl text-4xl font-bold mb-10 font-Sitka text-primary">
            {{ product.ref }}
          </h2>
          <p class="lg:text-other">
            {{ product.description }}
          </p>
          <p class="lg:text-other">
            price : {{ product.price }} DZD
          </p>
        </div>
        <div data-aos="fade-left" class="relative md:w-5/12 w-full h-80 ">
          <img class="w-full h-full absolute -bottom-3 left-1/2 -translate-x-1/2 h-38 pb-6" :src="product.image"
            alt="faucet1" />
        </div>
      </div>
      <div v-else
        class="flex md:flex-row-reverse flex-col justify-between items-center w-full bg-cardBg px-6 pt-6 my-10 rounded-3xl shadow-md overflow-hidden">
        <div data-aos="fade-left" class="md:text-right text-center md:w-1/2 w-full">
          <h2 class="lg:text-5xl text-4xl font-bold mb-10 font-Sitka text-primary">
            {{ product.ref }}
          </h2>
          <p class="lg:text-other">
            {{ product.description }}
          </p>
          <p class="lg:text-other">
            price : {{ product.price }} DZD
          </p>
        </div>
        <div data-aos="fade-right" class="relative md:w-5/12 w-full h-80 ">
          <img class="w-full h-full absolute -bottom-2 left-1/2 -translate-x-1/2 h-38 pb-6" :src="product.image"
            alt="faucet1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TCM202402NOIR from '@/assets/products/Chelia/TCM-202402-black.png'
import TCM202402CHROME from '@/assets/products/Chelia/TCM-202402-chrome.png'
import TCM202402BLANC from '@/assets/products/Chelia/TCM-202402-white.png'
import TCM202404NOIR from '@/assets/products/Chelia/TCM-202404-black.jpeg'
import TCM202404CHROME from '@/assets/products/Chelia/TCM-202404-chrome.jpeg'
import TCM202404BLANC from '@/assets/products/Chelia/TCM-202404-white.jpeg'
import TCM202407NOIR from '@/assets/products/Chelia/TCM-202407-black.jpeg'
import TCM202407CHROME from '@/assets/products/Chelia/TCM-202407-chrome.jpeg'
import TCM202407BLANC from '@/assets/products/Chelia/TCM-202407-white.jpeg'
import TCM202401NOIR from '@/assets/products/Chelia/TCM-202401-black.png'
import TCM202401CHROME from '@/assets/products/Chelia/TCM-202401-chrome.png'
import TCM202401BLANC from '@/assets/products/Chelia/TCM-202401-white.png'
import TCM202308 from '@/assets/products/Timgad/TCM-202308.jpeg'
import TCM202306 from '@/assets/products/Timgad/TCM-202306.jpeg'
import TCM202304 from '@/assets/products/Timgad/TCM-202304.jpeg'
import TCM202307 from '@/assets/products/Timgad/TCM-202307.jpeg'
import TCM202305 from '@/assets/products/Timgad/TCM-202305.jpeg'
import TCM202303 from '@/assets/products/Timgad/TCM-202303.jpeg'
import TCM202301 from '@/assets/products/Timgad/TCM-202301.jpeg'



import FaucetPic1 from "@/assets/faucet7.png";
import FaucetPic2 from "@/assets/faucet6.png";

export default {
  data() {
    return {
      FaucetPic1URL: FaucetPic1,
      FaucetPic2URL: FaucetPic2,
      products: [
        {
          "ref": "TCM-202402",
          "description": "CHELIA MITIGEUR ABLUTION RABATABLE - BLANC",
          "price": "6850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202402NOIR,
        },
        {
          "ref": "TCM-202402",
          "description": "CHELIA MITIGEUR ABLUTION RABATABLE - CHROME",
          "price": "5850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202402CHROME,
        },
        {
          "ref": "TCM-202402",
          "description": "CHELIA MITIGEUR ABLUTION RABATABLE - NOIRE",
          "price": "5850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202402BLANC,
        },
        {
          "ref": "TCM-202404",
          "description": "CHELIA MITIGEUR BAIN DOUCHE - BLANC",
          "price": "6850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202404NOIR,
        },
        {
          "ref": "TCM-202404",
          "description": "CHELIA MITIGEUR BAIN DOUCHE - CHROME",
          "price": "5850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202404CHROME,
        },
        {
          "ref": "TCM-202404",
          "description": "CHELIA MITIGEUR BAIN DOUCHE - NOIRE",
          "price": "5850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202404BLANC,
        },
        {
          "ref": "TCM-202407",
          "description": "CHELIA MITIGEUR CUISINE BL - BLANC",
          "price": "7850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202407NOIR,
        },
        {
          "ref": "TCM-202407",
          "description": "CHELIA MITIGEUR CUISINE BL - CHROME",
          "price": "6850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202407CHROME,
        },
        {
          "ref": "TCM-202407",
          "description": "CHELIA MITIGEUR CUISINE BL - NOIRE",
          "price": "7850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202407BLANC,
        },
        {
          "ref": "TCM-202401",
          "description": "CHELIA MITIGEUR LAVABO - BLANC",
          "price": "5850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202401NOIR,
        }
        ,
        {
          "ref": "TCM-202401",
          "description": "CHELIA MITIGEUR LAVABO - CHROME",
          "price": "4850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202401CHROME,
        }
        ,
        {
          "ref": "TCM-202401",
          "description": "CHELIA MITIGEUR LAVABO - NOIR",
          "price": "5850.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202401BLANC,
        }
        ,
        {
          "ref": "TCM-202308",
          "description": "TIMGAD MITIGEUR ABLUTION - 2",
          "price": "4100.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202308,
        }
        ,
        {
          "ref": "TCM-202306",
          "description": "TIMGAD MITIGEUR ABLUTION - 1",
          "price": "3150.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202306,
        }
        ,
        {
          "ref": "TCM-202304",
          "description": "TIMGAD MITIGEUR BAIN DOUCHE",
          "price": "4450.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202304,
        }
        ,
        {
          "ref": "TCM-202307",
          "description": "TIMGAD MITIGEUR CUISINE BL",
          "price": "3250.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202307,
        }
        ,
        {
          "ref": "TCM-202305",
          "description": "TIMGAD MITIGEUR CUISINE MURAL",
          "price": "3250.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202305,
        }
        ,
        {
          "ref": "TCM-202303",
          "description": "TIMGAD MITIGEUR DOUCHE",
          "price": "3350.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202303,
        }
        ,
        {
          "ref": "TCM-202401",
          "description": "TIMGAD MITIGEUR LAVABO",
          "price": "2650.00",
          "conditionnement": "Carton",
          "quantité": "12",
          image: TCM202301,
        }
      ]

      ,
      // products: [
      //   {
      //     title: "Nom de Produit",
      //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, officia cupiditate dolorem quia incidunt fugit accusamus cum nihil aperiam quo ea tenetur asperiores a iusto dicta eaque dolorum officiis reprehenderit.",
      //     productPicture: FaucetPic1,
      //   },
      //   {
      //     title: "Nom de Produit",
      //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, officia cupiditate dolorem quia incidunt fugit accusamus cum nihil aperiam quo ea tenetur asperiores a iusto dicta eaque dolorum officiis reprehenderit.",
      //     productPicture: FaucetPic2,
      //   },
      //   {
      //     title: "Nom de Produit",
      //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, officia cupiditate dolorem quia incidunt fugit accusamus cum nihil aperiam quo ea tenetur asperiores a iusto dicta eaque dolorum officiis reprehenderit.",
      //     productPicture: FaucetPic1,
      //   },
      // ],
    };
  },
};
</script>

<style scoped>
#products-container {
  overflow-y: visible;
  overflow-x: hidden;
}
</style>
